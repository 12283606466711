<template>
  <div class="container">
    <div class="postContainer" v-if="post">
      <div class="postHeader">
        <div class="postHeader">
          <div class="postId">#{{ post.id }}</div>
          <div class="postTitle">{{ post.title }}</div>
        </div>
        <div class="postDate">{{ post.date.toDateString() }}</div>
      </div>

      <div class="postBody" v-html="post.content"></div>
    </div>
  </div>
</template>

<script>
import autoRender from "katex/contrib/auto-render";

export default {
  props: ["p"],
  mounted() {
    autoRender(this.$el);
  },
  data() {
    let post = this.p;
    console.log(post, "post");
    post.date = new Date(post.created);
    return {
      post,
    };
  },
};
</script>

<style>
.postContainer {
  width: 100%;
  background: white;
  min-height: 15vh;
  padding: 10px;
  border-radius: 5px;
  max-width: 1200px;
  margin: auto;
}

.postId {
  background: rgb(250, 250, 250);
  color: black;
  border: 1px solid grey;
  font-size: 1.5em;
  padding: 5px;
  margin: 10px;
  min-width: 35px;
  text-align: center;
  align-content: center;
  font-family: monospace;
  border-radius: 2px;
}
.postTitle {
  font-size: 1.9em;
  padding-left: 5px;
  min-width: 300px;
  max-width: 900px;
}
.postHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.postDate {
  align-self: right;
  color: grey;
  font-size: 1em;
  font-family: monospace;
  padding: 10px;
}

.postBody {
  padding: 20px;
}
</style>