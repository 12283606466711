<template>
  <div class="usersContainer">
    <h2 class="center">Posts</h2>

    <ul class="list-group">
        <router-link v-for="user in users" replace="div" :key="user._id" :to="'/admin/edit/'+user._id">
      <li class="list-group-item" >
        <div class="d-flex justify-content-between p-1">
          <div>
            (#{{ user.id }}) {{ user.title }} - <span v-if="user.public">Public</span><span v-else>Private</span> -
            Length: {{ user.content.length }}
          </div>
          <div>{{ new Date(user.created).toLocaleString() }}</div>
        </div>
      </li></router-link>
    </ul>
  </div>
</template>

<script>
import talk from "@/talk.js";

export default {
  data() {
    return {
      users: [],
    };
  },
  async mounted() {
    let res = await talk.get("list?collection=posts");
    if (res.data) this.users = res.data;
  },
};
</script>

<style>
.usersContainer {
  max-width: 600px;
  margin: auto;
}
</style>