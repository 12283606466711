<template>
  <div class="page">
    <nav class="navbar navbar-dark bg-primary navbar-expand-sm">
      <div class="container center">
        <router-link class="navbar-brand" to="/">
          Admin Panel
          <div style="font-size: 15px; padding-left: 15px" v-if="user">
            {{ user.name }} ( {{ user.email }} )
          </div>
        </router-link>
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/admin">Status</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/admin/posts">Posts</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/admin/create">Create Post</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/admin/users">Users</router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link">Settings</a>
          </li>
        </ul>
      </div>
    </nav>

    <div class="p-3">
        <router-view></router-view>

    </div>

  </div>
</template>

<script>
import talk from "@/talk.js";

export default {
  data() {
    return {
      user: null,
    };
  },
  async mounted() {
    let user = await talk.getUser();
    if (user) this.user = user;
    if (user===null) this.$router.push("/login");
  },
};
</script>

<style>
.page {
  background: white;
  min-height: 80vh;
  max-width: 1900px;
  margin: auto;
  margin-top: 0;
}

.right {
  text-align: right;
}
</style>