<template>
  
  <div class="usersContainer">

      <h2 class="center">Users</h2>

      <ul class="list-group">
        <li class="list-group-item" v-for="user in users">
            
            {{user.id}} - {{user.name}} - {{user.email}} - {{new Date(user.created).toLocaleString()}}
        </li>
      </ul>

  </div>

</template>

<script>
import talk from "@/talk.js";

export default {
    data() {
        return {
            users: [],
        };
    },
    async mounted() {
        let res = await talk.get('list');
        if(res.data) this.users = res.data;
    }
}
</script>

<style>

.usersContainer{
    max-width: 600px;
    margin: auto;
}

</style>