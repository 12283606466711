<template>
  <footer class="bg-dark footer">

              <div class="d-flex flex-wrap justify-content-md-between justify-content-sm-center p-1 container">
              
                <div class="center">© John Dews-Flick {{year}}</div>  

                <div>
                    
                    <router-link to="/" class="m-2">Home</router-link>
                    <router-link to="/about" class="m-2">About</router-link>
                    <a href="" class="m-2">Github</a>
                    <router-link to="/contact" class="m-2">Contact</router-link>
                    <router-link to="/admin" class="m-2">Admin</router-link>
                </div>  

             

              </div>


  </footer>
</template>

<script>
export default {
    data(){
        return {
            year: new Date().getFullYear()
        }
    }

}
</script>

<style lang="scss">

.footer{
    color: white;
    padding: 10px;
    a {
        color: white;
        text-decoration: none;
    }
    .container{
        div{
            width: 300px;
            margin: auto;
        }
    }
}

</style>