var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container card-container"},_vm._l((_vm.cards),function(card){return _c('Swipeable',{key:card.id,style:({
      position: 'absolute',
      height: _vm.options.height,
      width: _vm.options.width,
      marginTop: card.disp[0] + 'px',
      marginLeft: card.disp[1]+ 'px',
      filter: 'drop-shadow(-3mm 4mm 4mm rgb(0, 0, 0, 0.1))',
      borderRadius: '8px'
    }),attrs:{"initalRotation":card.rot},on:{"swipe":_vm.onSwipe}},[_c('h1',[_vm._v("Hey bby")])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }