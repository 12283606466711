<template>
  <div>

    <div class="container bg-light p-2 mb-2 center">
      <h2>All Blog Posts</h2>
      
    </div>
    
    <div v-for="post in posts" :key="post._id" class="pb-2 pt-2">
      <PostItem :p="post"></PostItem>
    </div>

  </div>
</template>

<script>
import talk from "@/talk.js";
import PostItem from "@/components/Post.vue";

export default {
  components: {
    PostItem
  },

  data() {
    return {
      posts: [],
    };
  },
  async mounted() {
    let res = await talk.get("list?collection=posts");
    if (res.data) this.posts = res.data;
  },
};
</script>

<style>
</style>