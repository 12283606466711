<template>
  <div class="container card-container">


        <Swipeable
      v-for="card in cards"
      :key="card.id"
      v-on:swipe="onSwipe"
      :style="{
        position: 'absolute',
        height: options.height,
        width: options.width,
        marginTop: card.disp[0] + 'px',
        marginLeft: card.disp[1]+ 'px',
        filter: 'drop-shadow(-3mm 4mm 4mm rgb(0, 0, 0, 0.1))',
        borderRadius: '8px'
      }"
      :initalRotation="card.rot"
    >
    
    <h1>Hey bby</h1>
    
        </Swipeable>

  </div>
</template>

<script>

  import  Swipeable  from "vue-swipy/src/Swipeable.vue";

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

const a = 10.0;

export default {
  components: {
    Swipeable,
  },
  data() {
    let cards = new Array(10).fill(0).map((_, i) => {
      return {
        color: getRandomColor(),
        text: `Card ${i + 1}`,
        disp: [getRandomArbitrary(-a,a), getRandomArbitrary(-a,a)],
        rot: getRandomArbitrary(-a/3,a/3)
      };
    });
    return {
      options: {
        height: window.innerHeight*0.76 + 'px',
        width: window.innerWidth*0.5 + 'px',
      },
      cards: [...cards]
    };
  },
  methods: {
      loadMore(){
        this.cards.unshift({ id: Math.random(), color: getRandomColor(), disp: [getRandomArbitrary(-a,a), getRandomArbitrary(-a,a)], rot: getRandomArbitrary(-a/3,a/3)});
  
      },
      onSwipe(direction) {
        console.log(direction);
        setTimeout(async () => {
          this.cards.pop();
          await this.$forceUpdate();
          this.loadMore();          
        }, 100);
      },
    }
};

</script>

<style>

.card-container{
    width: 95vw;
    max-width: 1200px;
    max-height: 100vh;
    margin: auto;
    padding: 0px;
    overflow: hidden;
    padding: 50px;
    

}


.swipeCard{
    background: #ffeae4;
    border: solid rgba(0, 0, 0, 0.555) 2px;

          user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
}

.swipeCard:hover{
    background: #ffeae4;
    border: solid rgba(0, 0, 0, 1) 2px;
}

</style>