<template>
  <div class="editContainer">
    <div class="row">
      <div class="col">
        <div class="editorContainer">
          <h2>Edit Post</h2>
          <div class="d-flex justify-content-between">

          <div class="input-group mb-3" style="max-width: 600px">
            <span class="input-group-text" id="inputGroup-sizing-default"
              >Post Title</span
            >
            <input
              type="text"
              class="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              v-model="post.title"
            />
          </div>

          <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="post.public">
  <label class="form-check-label" for="flexCheckDefault">
    Public
  </label>
</div>
 </div>
          <div>
            <VueEditor
              :customModules="customModulesForEditor"
              :editorOptions="editorSettings"
              v-model="post.content"
            ></VueEditor>
          </div>

          <div class="d-flex justify-content-between p-1">
            <button
              type="button"
              class="btn btn btn-outline-primary"
              @click="showPreview = !showPreview"
            >
              {{ showPreview ? "Hide" : "Show" }} Preview
            </button>
            <button @click="createPost()" type="button" class="btn btn-primary">Create Post</button>
          </div>
        </div>
      </div>

      <div class="col" v-if="showPreview">
        <div class="d-flex justify-content-between p-1">
          <h5>Preview:</h5>
          <button
            type="button"
            class="btn btn btn-outline-primary btn-sm"
            @click="showCode = !showCode"
          >
            {{ showCode ? "Live" : "Code" }} View
          </button>
        </div>

        <div class="previewContainer">
          <div class="preview">
            <div v-if="showCode">{{ post.content }}</div>
            <div v-else v-html="post.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import talk from "@/talk.js";
import { VueEditor } from "vue2-editor";
import htmlEditButton from "quill-html-edit-button";

export default {
  components: { VueEditor },

  methods:{
    async createPost(){
      let post = this.post;
      if(post.title && post.content){
        let post = await talk.createDocument('posts', this.post);
        console.log(post);
        this.$router.push("/admin/posts");
      }
    }

  },

  data() {
    return {
      users: [],
      content: "",
      post: {
        title: "",
        content: "",
        public: true
      },
      showCode: false,
      showPreview: false,

      customModulesForEditor: [
        { alias: "htmlEditButton", module: htmlEditButton },
      ],
      editorSettings: {
        modules: {
          htmlEditButton: {},
        },
      },
    };
  },
  async mounted() {
    let res = await talk.get("list");
    if (res.data) this.users = res.data;
  },
};
</script>

<style lang="scss">
.editContainer {
  max-width: 100%;
  margin: auto;
}

.editorContainer {
  max-width: 1200px;
  margin: auto;
}

$pScale: 1;
$pInv: 1 / $pScale;

.preview {
  border: 1px dashed black;
  width: calc(100% * $pInv);
  transform: scale($pScale);
  transform-origin: left top;
}

.previewContainer {
  max-height: 70vh;
  overflow: auto;
  max-width: 100%;
}

.ql-editor {
  max-height: 60vh;
  overflow: auto;
}
</style>