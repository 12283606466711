<template>
  
<div>
    <div class="layout">

      <router-view></router-view>
  </div>      
  <Footer></Footer>

</div>


</template>

<script>

import Footer from "@/components/Footer.vue";


export default {
  components: {
    Footer
  },

}
</script>

<style lang="scss">

.layout{
    min-height: calc(100vh - 50px);
    min-width: 100vw;
    background-color: #292929;
    padding: 15px;
}

body{
  background: #292929;
}

</style>