import axios from 'axios';

let apiUrl = '/api/';
if(window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1') {
   apiUrl = 'http://127.0.0.1:8788/api/';
}
let token = null;
let user = null;

const errorHandler = (err) => {
    console.log(err);
    err.data = err.response.data;

    return {failed: true, ...err};
};

const dataHandler = (a) => {

    return a;
}

const get = (url, params) => {
    return axios.get(apiUrl + url, { params }).catch(errorHandler).then(dataHandler);
}

const post = (url, data) => {
    return axios.post(apiUrl + url, data, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).catch(errorHandler).then(dataHandler);
}

const storeUser = (obj) => {
    user = obj.user;
    token = obj.token;
    localStorage.setItem('user', JSON.stringify(obj));
}

const getToken = () => {
    if(token) return token;
    let obj = localStorage.getItem('user');
    if(!obj) return null;
    if(obj) obj = JSON.parse(obj);
    if(obj && obj.token) token = obj.token;
    user = obj.user;
    return token;
}

const login = async (email, password) => {
    let p = { email, password };
    let res = await post('login', p);
    if(res.failed) return res;
    if(res.data.token)
        storeUser(res.data);
    
    return user;
}

const getUser = async () => {
    getToken();
    if(user) return user;
    return null;
}

const createDocument = async (collection, doc) => {
    let res = await post('create', {collection, doc});
    if(res.failed) return res;
    return res.data;
}







export default { get, post, login, getToken, storeUser, getUser, createDocument };