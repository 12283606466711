import Vue from 'vue'
import VueRouter from 'vue-router'
import PostView from '../components/Post.vue'
import Cards from '../views/Cards.vue'
import Layout from '../views/Layout.vue'
import Login from '../views/admin/Login.vue'
import AdminHome from '../views/admin/AdminHome.vue'
import Users from '../views/admin/Users.vue'
import Edit from '../views/admin/EditPost.vue'
import Posts from '../views/admin/Posts.vue'
import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home
      },
      {
        path: '/login',
        name: 'login',
        component: Login
      },
   
      {
        path: '/admin',
        name: 'Admin Home',
        component: AdminHome,
        children: [
          {
            path: '',
            name: 'home',
            component: Posts
          },
          {
            path: 'users',
            name: 'users',
            component: Users
          },
          {
            path: 'posts',
            name: 'posts',
            component: Posts
          },
          {
            path: 'create',
            name: 'create',
            component: Edit
          },
          {
            path: 'edit/:id',
            name: 'edit',
            component: Edit
          }
        
        
        ]
            
      }
    ]
        
  },
  {
    path: '/cards',
    name: 'cards',
    component: Cards
  },
  //{
  //  path: '/about',
  //  name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  //  component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
