<template>
  <div>
    <img src="@/assets/dewsLogoLight.png" class="logo" alt="" />

    <div class="loginContainer">
      <h3 class="center pb-4">Sign In</h3>

      <div class="alert alert-danger center" v-if="error">{{error}}</div>

      <form class="loginForm" @submit.prevent="submit()">
        <div class="input-group mb-3">
          <input
            type="email"
            class="form-control"
            placeholder="Email"
            aria-label="Email"
            aria-describedby="basic-addon1"
            v-model="email"
            required
          />
        </div>

        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Password"
            aria-label="Password"
            v-model="password"
            required
          />
        </div>

        <div class="d-grid gap-2">
          <button class="btn btn-primary" type="submit">
            Login
          </button>
        </div>
      </form>

      <div class="loginFooter">
        <p>Internal Use Only</p>
      </div>
    </div>
  </div>
</template>

<script>

import talk from "@/talk.js";

export default {
  data() {
    return {
      email: "",
      password: "",
      error: ""
    };
  },
  methods: {
    async submit() {
      let email = this.email;
      let password = this.password;

        let res = await talk.login(email, password);
        if(res.failed) this.error = res.data;
        console.log(res);
        if(res.id) {
          this.$router.push("/admin");
        }

    },
  },
};
</script>

<style lang="scss" scoped>
$height: 300px;

.loginContainer {
  max-width: 350px;
  min-height: $height;
  width: 100%;
  margin: auto;
  padding: 25px;
  background-color: #eeeeee;
  border-radius: 2px;
  margin-top: calc(5vh);

  .loginForm {
    min-height: $height - 150px;
  }

  .loginFooter {
    text-align: center;
  }
}

.logo {
  width: 200px;
  margin: auto;
  display: block;
  margin-top: 5vh;
}
</style>